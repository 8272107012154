class Base
{
 calendar()
 {
  let dateNow = new Date();

  $(".readonly").keydown(function(e){
   e.preventDefault();
  });

  $('.date').datetimepicker({
   allowInputToggle: true,
   format: 'DD-MM-Y hh:mm:ss A',
   ignoreReadonly: true,
   useCurrent: false,
   widgetPositioning:{
    horizontal: 'auto',
    vertical: 'bottom'
   }
  });

  $('.date_single').datetimepicker({
   allowInputToggle: true,
   format: 'DD-MM-Y',
   ignoreReadonly: true,
   useCurrent: false,
   widgetPositioning:{
    horizontal: 'auto',
    vertical: 'bottom'
   }
  });
 }

 changeShop()
 {
  $('.changeShop').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de este punto de venta?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo le concede o activa el acceso a la plataforma, dependiendo del estado.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/shop/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>El punto de venta seleccionado ha sido activado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>El punto de venta seleccionado ha sido desactivado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del punto de venta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del punto de venta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado del punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El punto de venta seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeTire()
 {
  $('.changeTire').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de esta llanta?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede aparecer o no al momento de crear un caso.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/tire/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>La llanta seleccionada ha sido activada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>La llanta seleccionada ha sido desactivada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la llanta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la llanta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado de la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La llanta seleccionada no ha sido afectada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeUser()
 {
  $('.changeUser').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de este funcionario?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede conderle o negarle el acceso a la plataforma.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/user/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>El funcionario ha sido activado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>El funcionario seleccionado ha sido desactivado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del funcionario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del funcionario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del funcionario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado del funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del funcionario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El funcionario seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 destroyShop()
 {
  $('.destroyShop').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar este punto de venta?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/shop/${id}`, {
      method: 'DELETE',
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>El punto de venta seleccionado ha sido eliminado</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el punto de venta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el punto de venta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado el punto de venta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El punto de venta seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 destroyTire()
 {
  $('.destroyTire').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar esta llanta?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/tire/${id}`, {
      method: 'DELETE',
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>La llanta seleccionada ha sido eliminada</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la llanta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la llanta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado la llanta, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La llanta seleccionada no ha sido afectada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 destroyUser()
 {
  $('.destroyUser').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar este funcionario?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/user/${id}`, {
      method: 'DELETE',
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>El funcionario seleccionado ha sido eliminado</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el funcionario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el funcionario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el funcionario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado el funcionario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el funcionario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El funcionario seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 detectBrowser()
 {
  let getBrowserInfo = function()
  {
   let ua= navigator.userAgent, tem,
   M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

   if(/trident/i.test(M[1]))
   {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE '+(tem[1] || '');
   }

   if(M[1]=== 'Chrome')
   {
    tem= ua.match(/\b(OPR|Edge)\/(\d+)/);

    if(tem!= null)
     return tem.slice(1).join(' ').replace('OPR', 'Opera');
   }

   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];

   if((tem= ua.match(/version\/(\d+)/i))!= null)
    M.splice(1, 1, tem[1]);

   return M.join(' ');
  };

  if(getBrowserInfo().substr(0,2)=='IE'){
   alert('¡Atención!, Esta usando Internet Explorer, este navegador no es compatible con algunas funciones del sitio web, por favor utilice otro navegador para una mejor experiencia, preferiblemente Google Chrome o Firefox');
  }
 }

 loadExcel()
 {
  jQuery('.shopFile').change(function()
  {
   let filename = jQuery(this).val().split('\\').pop();
   let label = $("label[for='" + $(this).attr('id') + "']");
   $(label).text(filename);
   $('#sendExcel').removeClass('d-none');
  });
 }

 selectDesign()
 {
  let reference = $(".select_reference");
  let btnSubmit = $("#btnSubmit");

  $(".select_design").change(function()
  {
   let url = `/case/reference/${ $("#mark").text() }/${ $("#design").val() }`;

   btnSubmit.removeClass("d-none");
   reference.select2();
   reference.load(url);
  });

  $(".select_design").keyup(function()
  {
   let url = `/case/reference/${ $("#mark").text() }/${ $("#design").val() }`;

   btnSubmit.removeClass("d-none");
   reference.select2();
   reference.load(url);
  });
 }

 selectMark()
 {
  let design = $(".select_design");
  let div_design = $("#div_design");

  $(".select_mark").change(function()
  {
   let url = `/case/design/${ $("#mark").val() }`;

   div_design.removeClass("d-none");
   design.select2();
   design.load(url);
  });

  $(".select_mark").keyup(function()
  {
   let url = `/case/design/${ $("#mark").val() }`;

   div_design.removeClass("d-none");
   design.select2();
   design.load(url);
  });
 }

 selectMeasure()
 {
  let measure = $(".select_measure");
  let btnSubmit = $("#btnSubmit");

  measure.select2();

  $(".select_measure").change(function()
  {
   btnSubmit.removeClass("d-none");
  });

  $(".select_measure").keyup(function()
  {
   btnSubmit.removeClass("d-none");
  });
 }

 selectReference()
 {
  let measure = $(".select_measure");
  let div_measure = $("#div_measure");

  $(".select_reference").change(function()
  {
   let url = `/case/measure/${ $("#reference").val() }`;

   div_measure.removeClass("d-none");
   measure.select2();
   measure.load(url);
  });

  $(".select_reference").keyup(function()
  {
   let url = `/case/measure/${ $("#reference").val() }`;

   div_measure.removeClass("d-none");
   measure.select2();
   measure.load(url);
  });
 }

 showPassword()
 {
  let password = document.querySelector('#password');
  let password_confirmation = document.querySelector('#password_confirmation');

  $('#showPassword').click(function()
  {
   if(password.type === "password"){
    password.type = "text";
   }
   else{
    password.type = "password";
   }
  });

  $('#showConfirmPassword').click(function()
  {
   if(password_confirmation.type === "password"){
    password_confirmation.type = "text";
   }
   else{
    password_confirmation.type = "password";
   }
  });
 }

 showPhoto()
 {
  $('.showPhoto').click(function()
  {
   let photo = $(this).attr('src');

   Swal.fire({
    imageUrl: photo,
    padding: '0.1rem',
    width: '75%'
   })
  });
 }

 showShop()
 {
  $('.showShop').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/shop/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Distribuidor: '+data.distributor+'</p>'
      +' '+
      '<p class="m-0 small">Dirección: '+data.address+'</p>'
      +' '+
      '<p class="m-0 small">Ciudad: '+data.city+'</p>'
      +' '+
      '<p class="m-0 small">Departamento: '+data.department+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 1: '+data.phone+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 2: '+data.phone_2+'</p>'
      +' '+
      '<p class="m-0 small">Email 1: '+data.email+'</p>'
      +' '+
      '<p class="m-0 small">Email 2: '+data.email_2+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+data.state+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos del punto de venta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del punto de venta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del punto de venta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del punto de venta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos del punto de venta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del punto de venta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showTire()
 {
  $('.showTire').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/tire/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.reference,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Marca: '+data.mark+'</p>'
      +' '+
      '<p class="m-0 small">Diseño: '+data.design+'</p>'
      +' '+
      '<p class="m-0 small">Artículo: '+data.article+'</p>'
      +' '+
      '<p class="m-0 small">Medida: '+data.measure+'</p>'
      +' '+
      '<p class="m-0 small">Referencia: '+data.reference+'</p>'
      +' '+
      '<p class="m-0 small">Status: '+data.status+'</p>'
      +' '+
      '<p class="m-0 small">Batch: '+data.batch+'</p>'
      +' '+
      '<p class="m-0 small">Profundidad de Labrado: '+data.tilling_depth+'</p>'
      +' '+
      '<p class="m-0 small">Segmento: '+data.segment+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+data.state+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos de la llanta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la llanta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la llanta porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos de la llanta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos de la llanta, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la llanta porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 timer()
 {
  let url = '/session';
  let minutes = 720;

  fetch(url)
   .then(function(response){
    if(response.ok){
     return response.text();
    }
   })
   .catch(function(error)
   {
    Swal.fire({
     icon: "error",
     title: "<h6 class='text-center'>Atención</h6>",
     html: "<h5 class='text-center'>Por favor revise su conexión a internet.</h5>",
     confirmButtonColor: '#FFA500'
    })
   })
   .then(function(data)
   {
    if(data!='No logueado')
    {
     let number = 60000*minutes;

     setTimeout( function()
     {
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Su sesión ha caducado, por favor vuelva a loguearse.</h5>",
       confirmButtonColor: '#FFA500'
      })

      location.href = '/logout';
     }, number);
    }
   });
 }
}

window.onload = () => {
 let base = new Base();

 base.calendar();
 base.changeShop();
 base.changeTire();
 base.changeUser();
 base.destroyShop();
 base.destroyTire();
 base.destroyUser();
 base.detectBrowser();
 base.loadExcel();
 base.selectDesign();
 base.selectMark();
 base.selectMeasure();
 base.selectReference();
 base.showPassword();
 base.showPhoto();
 base.showShop();
 base.showTire();
 base.timer();
};
