window._ = require('lodash');

try
{
 window.$ = window.jQuery = require('jquery');
 window.jQuery_Easing = require('jquery.easing');
 window.Popper = require('popper.js').default;
 window.Bootstrap = require('bootstrap');
 window.Bootstrap_Select = require('bootstrap-select');
 window.Moment = require('./moment/moment.min.js');
 window.Datetimepicker = require('./datetimepicker/bootstrap-datetimepicker.min.js');
 window.Swal = require('sweetalert2');
 window.Select = require('./select/select.min.js');
 window.Sb_Admin = require('startbootstrap-sb-admin-2/js/sb-admin-2');
 window.Base = require('./other/base');
}
catch(e){}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if(token){
 window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else{
 console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
